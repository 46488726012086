exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artists-js": () => import("./../../../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-basinamani-js": () => import("./../../../src/pages/basinamani.js" /* webpackChunkName: "component---src-pages-basinamani-js" */),
  "component---src-pages-basinilha-js": () => import("./../../../src/pages/basinilha.js" /* webpackChunkName: "component---src-pages-basinilha-js" */),
  "component---src-pages-basinkain-js": () => import("./../../../src/pages/basinkain.js" /* webpackChunkName: "component---src-pages-basinkain-js" */),
  "component---src-pages-bath-js": () => import("./../../../src/pages/bath.js" /* webpackChunkName: "component---src-pages-bath-js" */),
  "component---src-pages-bath-l-js": () => import("./../../../src/pages/bathL.js" /* webpackChunkName: "component---src-pages-bath-l-js" */),
  "component---src-pages-catalinaruiz-js": () => import("./../../../src/pages/catalinaruiz.js" /* webpackChunkName: "component---src-pages-catalinaruiz-js" */),
  "component---src-pages-fixtures-js": () => import("./../../../src/pages/fixtures.js" /* webpackChunkName: "component---src-pages-fixtures-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-martinreyna-js": () => import("./../../../src/pages/martinreyna.js" /* webpackChunkName: "component---src-pages-martinreyna-js" */),
  "component---src-pages-newmilan-js": () => import("./../../../src/pages/newmilan.js" /* webpackChunkName: "component---src-pages-newmilan-js" */),
  "component---src-pages-neworigin-js": () => import("./../../../src/pages/neworigin.js" /* webpackChunkName: "component---src-pages-neworigin-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-origin-js": () => import("./../../../src/pages/origin.js" /* webpackChunkName: "component---src-pages-origin-js" */),
  "component---src-pages-privacity-js": () => import("./../../../src/pages/privacity.js" /* webpackChunkName: "component---src-pages-privacity-js" */),
  "component---src-pages-seats-js": () => import("./../../../src/pages/seats.js" /* webpackChunkName: "component---src-pages-seats-js" */),
  "component---src-pages-tray-js": () => import("./../../../src/pages/tray.js" /* webpackChunkName: "component---src-pages-tray-js" */)
}

